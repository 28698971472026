var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("page-section", { style: _vm.styles.fv, attrs: { id: "fv" } }, [
    _c(
      "div",
      { staticStyle: { position: "relative" } },
      [
        _c("div", { style: _vm.styles.title, attrs: { id: "title" } }, [
          _vm._v(" 料金・導入フロー "),
        ]),
        _c("div", { style: _vm.styles.subtitle, attrs: { id: "subtitle" } }, [
          _vm._v(" Tuttiの導入をご検討されている方向けに、"),
          _c("br"),
          _vm._v(" 料金体系・導入までのフローをご紹介します。"),
          _c("br"),
          _vm._v(" 弊社担当から直接詳細なご説明も可能です。"),
          _c("br"),
          _vm._v(" まずはお気軽にお問い合わせください。 "),
        ]),
        _c("contact-button", {
          staticClass: "mt-12",
          style: _vm.styles.contactButton,
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }