var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-section",
    { staticClass: "grey lighten-4" },
    [
      _c("page-section-title", { attrs: { title: "Tutti 導入フロー" } }),
      _c(
        "v-timeline",
        { attrs: { dense: "" } },
        [
          _c(
            "v-timeline-item",
            { attrs: { small: "", "fill-dot": "", color: "#FFE443" } },
            [
              _c(
                "v-row",
                { staticClass: "row-step", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "col-step",
                      attrs: {
                        cols: _vm.$vuetify.breakpoint.forPC ? false : 12,
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/web-figures/installation-step-01.png"),
                        },
                      }),
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: _vm.$vuetify.breakpoint.forPC ? false : 12,
                      },
                    },
                    [
                      _c("p", { staticClass: "step-title" }, [
                        _vm._v("お問い合わせ"),
                      ]),
                      _c("p", { staticClass: "step-body" }, [
                        _vm._v(
                          " Tuttiのツールとしてのご利用はもちろん、カスタムでのラベリングシステムの開発や、 データラベル収集の運用なども広く承っております。"
                        ),
                        _c("br"),
                        _vm._v(" まずはお気軽にご相談ください。 "),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-timeline-item",
            { attrs: { small: "", "fill-dot": "", color: "#FFE443" } },
            [
              _c(
                "v-row",
                { staticClass: "row-step", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "col-step",
                      attrs: {
                        cols: _vm.$vuetify.breakpoint.forPC ? false : 12,
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/web-figures/installation-step-02.png"),
                        },
                      }),
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: _vm.$vuetify.breakpoint.forPC ? false : 12,
                      },
                    },
                    [
                      _c("p", { staticClass: "step-title" }, [
                        _vm._v("ヒアリング・ご提案"),
                      ]),
                      _c("p", { staticClass: "step-body" }, [
                        _vm._v(
                          " 貴社の課題やご要望を伺いながら、最適なプランをご提案いたします。 "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-timeline-item",
            { attrs: { small: "", "fill-dot": "", color: "#FFE443" } },
            [
              _c(
                "v-row",
                { staticClass: "row-step", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "col-step",
                      attrs: {
                        cols: _vm.$vuetify.breakpoint.forPC ? false : 12,
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/web-figures/installation-step-03.png"),
                        },
                      }),
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: _vm.$vuetify.breakpoint.forPC ? false : 12,
                      },
                    },
                    [
                      _c("p", { staticClass: "step-title" }, [
                        _vm._v("ご発注"),
                      ]),
                      _c("p", { staticClass: "step-body" }, [
                        _vm._v(
                          " Tuttiをかんたんにアクセス可能な環境にてご用意いたします。"
                        ),
                        _c("br"),
                        _vm._v(
                          " ご利用開始後も使い方やシステム開発をサポートいたします。 "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }