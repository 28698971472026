var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex align-center my-4" },
    [
      !_vm.left ? _c("v-divider", { attrs: { color: "black" } }) : _vm._e(),
      _c("p", {
        class: [_vm.mx, "mb-0", "font-weight-bold"],
        style: _vm.fontStyle,
        attrs: { id: "text" },
        domProps: { innerHTML: _vm._s(_vm.text) },
      }),
      !_vm.right ? _c("v-divider", { attrs: { color: "black" } }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }