var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-section",
    { staticClass: "prices" },
    [
      _c("page-section-title", { attrs: { title: "ご料金体系" } }),
      _c("div", { style: _vm.styles.subtitle, attrs: { id: "subtitle" } }, [
        _vm._v(" Tutti では、"),
        !_vm.$vuetify.breakpoint.forPC ? _c("br") : _vm._e(),
        _vm._v("業務内容や解決したい課題をお伺いし、"),
        _c("br"),
        _vm._v(" お客様に合った最適な料金をご提案いたします。"),
        _c("br"),
        _vm._v(" まずはお気軽にお問い合わせください。 "),
      ]),
      _c("text-horizontal-line", {
        staticClass: "my-12",
        attrs: { text: "料金ケース例" },
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: _vm.$vuetify.breakpoint.forPC ? 4 : 12 } },
            [
              _c("price-card", {
                attrs: {
                  num: "1",
                  "scheme-fig-src": _vm.getSchemeFigSrcForIndex(1),
                },
                scopedSlots: _vm._u([
                  {
                    key: "description",
                    fn: function () {
                      return [_vm._v(" Tutti環境導入のみ ")]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: _vm.$vuetify.breakpoint.forPC ? 4 : 12 } },
            [
              _c("price-card", {
                attrs: {
                  num: "2",
                  "scheme-fig-src": _vm.getSchemeFigSrcForIndex(2),
                },
                scopedSlots: _vm._u([
                  {
                    key: "description",
                    fn: function () {
                      return [
                        _c("span", { staticClass: "case-box" }, [
                          _vm._v("case 1"),
                        ]),
                        _vm._v(" ＋ ラベリングシステム"),
                        _c("br"),
                        _vm._v("カスタム開発 "),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: _vm.$vuetify.breakpoint.forPC ? 4 : 12 } },
            [
              _c("price-card", {
                attrs: {
                  num: "3",
                  "scheme-fig-src": _vm.getSchemeFigSrcForIndex(3),
                },
                scopedSlots: _vm._u([
                  {
                    key: "description",
                    fn: function () {
                      return [
                        _c("span", { staticClass: "case-box" }, [
                          _vm._v("case 2"),
                        ]),
                        _vm._v(" ＋ クラウドソーシングへの"),
                        _c("br"),
                        _vm._v("ラベリング発注代行 "),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }