var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    [
      _c("prices-first-view-section"),
      _c("prices-section"),
      _c("call-to-action-section"),
      _c("installation-section"),
      _c("call-to-action-section"),
      _c("footer-section"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }