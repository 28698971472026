import { render, staticRenderFns } from "./InstallationSection.vue?vue&type=template&id=552c486a&scoped=true&"
import script from "./InstallationSection.vue?vue&type=script&lang=js&"
export * from "./InstallationSection.vue?vue&type=script&lang=js&"
import style0 from "./InstallationSection.vue?vue&type=style&index=0&id=552c486a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "552c486a",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';
installComponents(component, {VCol,VRow,VTimeline,VTimelineItem})


/* hot reload */
if (module.hot) {
  var api = require("/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('552c486a')) {
      api.createRecord('552c486a', component.options)
    } else {
      api.reload('552c486a', component.options)
    }
    module.hot.accept("./InstallationSection.vue?vue&type=template&id=552c486a&scoped=true&", function () {
      api.rerender('552c486a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/InstallationSection.vue"
export default component.exports