var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { style: _vm.styles.card, attrs: { id: "card" } },
    [
      _c("v-sheet", { attrs: { id: "title" } }, [
        _vm._v(" case "),
        _c("span", {
          style: _vm.styles.num,
          domProps: { textContent: _vm._s(_vm.num) },
        }),
      ]),
      _c(
        "v-sheet",
        { attrs: { id: "content" } },
        [
          _c(
            "v-row",
            { staticStyle: { height: "120px" }, attrs: { align: "center" } },
            [_c("v-col", [_vm._t("description")], 2)],
            1
          ),
          _c("div", { attrs: { id: "scheme" } }, [
            _c("img", {
              style: _vm.styles.schemeFig,
              attrs: { src: _vm.schemeFigSrc },
            }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }