// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/web-figures/background-grid.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.prices[data-v-b500f48a] {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-repeat: repeat;\n    font-size: 16px;\n}\n.case-box[data-v-b500f48a] {\n    background-color: rgb(252, 226, 99);\n    width: -webkit-fit-content;\n    width: -moz-fit-content;\n    width: fit-content;\n    padding: 5px 10px;\n    margin: 0 auto 10px auto;\n    border-radius: 5px;\n}\n#subtitle[data-v-b500f48a] {\n    text-align: center;\n}\n", ""]);
// Exports
module.exports = exports;
